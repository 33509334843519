import React, { Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CheckIcon from '@mui/icons-material/Check';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Avis extends Component {

    render() {

        return (
            <section className="testimonial__area pt-50 pb-50" style={{ backgroundImage: `url(${'assets/img/testimonial/home-3/testimonial-bg-3.jpg'})` }}>
                <div className="container">
                    <div className="row justify-content-center align-items-center"> 
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="testimonial__video-content d-sm-flex"> 
                                    <div className="testimonial__video-text">
                                        <h3> <CheckIcon class="font-icon-check" /> ATOUTS DE LA FORMATION </h3>
                                        <ul>
                                            <li className='Benificer'>
                                            Une formation unique au Maroc;
                                            </li>
                                            <li className='Benificer'>
                                            Une formation pluridisciplinaire de haut niveau
                                            </li>
                                            <li className='Benificer'>
                                             Un corps professoral expert national et international
                                            </li>
                                            <li className='Benificer'>
                                             Un campus résidentiel et des équipements modernes
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="testimonial__video ml-70 fix">
                                <div className="testimonial__thumb-3">
                                    <iframe src="https://www.youtube.com/embed/PaApR7AmBcQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                           </div>
                          </div> 
                       
                            </div>
                        </div>
            </section>
        );
    }
}

export default Avis;